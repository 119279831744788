import React from "react";
import ReactModal from "react-modal";
import * as Containers from "../redux-containers";
import { EmptyObject } from "../../misc/common";
import { CmsService } from "../../services/cms-service";
import { Actions } from "../../actions/dynamicFeedbackOverlay";
import { extendSession } from "../../services/authorize-service";
import { createSession, getSessionDetails } from '../../misc/session';

interface DynamicFeedbackOverlayStateProps {
    isOpen: boolean;
    allText: any;
    languageId: number;
    dynamicFeedbackLink: string | undefined;
    showTimeoutWarningModal: boolean;
}

interface DynamicFeedbackOverlayDispatchProps {
    close: () => void;
    open: () => void;
}

export type DynamicFeedbackOverlayProps = DynamicFeedbackOverlayStateProps &
    DynamicFeedbackOverlayDispatchProps;

class DynamicFeedbackOverlay extends React.Component<
    DynamicFeedbackOverlayProps,
    EmptyObject
> {

    private extendSessionCallTimeout: number | null = null;
    private extendSessionTriggered: boolean = false;

    constructor(props: DynamicFeedbackOverlayProps) {
        super(props);
    }

    public componentDidMount() {
        ReactModal.setAppElement("body");
        window.addEventListener('message', this.handleIframeMessage);
    }

    private handleIframeMessage = (event: any) => {

        if (this.props.showTimeoutWarningModal || this.extendSessionTriggered) return;

        const sessionDetails = getSessionDetails();
        /**
         * If last JWT issued was more than 2 minutes ago then refresh the JWT
         */
        const allowRefreshJWT = Date.now() - sessionDetails.issued.getTime() >= 120000

        if (
            event.origin === this.getUrlOrigin(this.props.dynamicFeedbackLink!) &&
            event.data?.type === "dfr-activity" &&
            allowRefreshJWT &&
            !this.extendSessionTriggered
        ) {
            this.extendSessionTriggered = true;

            extendSession()
                .then(newJWT => {
                    createSession(newJWT);
                });

            if (this.extendSessionCallTimeout !== null) clearTimeout(this.extendSessionCallTimeout);
            this.extendSessionCallTimeout = window.setTimeout(() => { this.extendSessionTriggered = false }, 10000);
        }
    };

    private getUrlOrigin = (url: string) => {
        const parsedDFRUrl = new URL(url);
        return parsedDFRUrl.origin
    }

    componentWillUnmount(): void {
        window.removeEventListener('message', this.handleIframeMessage);
    }

    public render() {
        const isOpen: boolean = !!this.props.isOpen;
        const dynamicFeedbackLaunchURL = this.props.dynamicFeedbackLink

        return (
            <ReactModal
                isOpen={isOpen}
                className={`${CmsService.GetSiteFontStyle()} dynamic-feedback-overlay`}
                overlayClassName="df-modal-overlay"
                ariaHideApp={false}
            >
                <div className="modal-body">
                    <button type="button" className="btn-close" aria-label="Close" onClick={this.handleClose}></button>
                    <iframe
                        src={dynamicFeedbackLaunchURL}
                        title="Dynamic Feedback Report"
                    ></iframe>
                </div>
            </ReactModal>
        );
    }

    private handleClose = () => {
        this.props.close();
        /**
         * Enable back the dashboard page scrollbar on closing the modal.
         */
        document.body.style.overflow = '';
    }
}

export default Containers.createStateAndDispatchWithProps<
    DynamicFeedbackOverlayStateProps,
    DynamicFeedbackOverlayDispatchProps,
    EmptyObject
>(
    DynamicFeedbackOverlay,
    (state) => ({
        isOpen: state.dynamicFeedback.showDynamicFeedbackOverlay,
        dynamicFeedbackLink: state.dynamicFeedback.dynamicFeedbackLink,
        allText: state.language.alltext,
        languageId: state.language.languageId,
        showTimeoutWarningModal: state.session.showTimeoutWarning
    }),
    (dispatch) => ({
        close: () => dispatch<any>(Actions.showDynamicFeedbackOverlay({ show: false })),
        open: () => dispatch<any>(Actions.showDynamicFeedbackOverlay({ show: true })),
    })
);
